import React from "react";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import {graphql, useStaticQuery} from "gatsby";

const PreciosAnuales = ({items, lang}) => {
    const query = useStaticQuery(graphql`
    query {
        menu: markdownRemark (
            frontmatter: {
                name: { eq: "prices-signature" }
            }
        ) {
            frontmatter {
                en {
                    features
                    enough
                    consult
                    contact_text
                    sales
                    month
                    year
                    button
                    simple_signature
                    advanced_biometric_signature
                    certified_mail
                    additional_user
                    alerts_and_reminders
                    custom_templates
                    shipment_scheduling
                    verification_dni
                    mass_shipments
                    organizations_teams
                    branding
                    api_integration
                }
                es{
                    features
                    enough
                    consult
                    contact_text
                    sales
                    month
                    year
                    button
                    simple_signature
                    advanced_biometric_signature
                    certified_mail
                    additional_user
                    alerts_and_reminders
                    custom_templates
                    shipment_scheduling
                    verification_dni
                    mass_shipments
                    organizations_teams
                    branding
                    api_integration
                }
            }
        }
    }
    `);
    const translate = query.menu.frontmatter[lang];

    return (
        <div className="mobile custom-table mt-5 mb-5">
            <hr className="new4" style={{position: 'absolute', zIndex: '9999'}}/>

            <table>
                <thead>
                <tr className="spacing">
                    <th rowSpan="2" className="bg-white">
                        {translate.features}
                    </th>
                    {items.map((item, i) => {
                        return <th className="pt-7" key={i}>{item.name}</th>
                    })}
                </tr>
                <tr className="spacing">
                    {items.map((item, i) => {
                        if (!item.contact_sales) {
                            return <td key={i}>
                                <span className="price font-price-large" data-annual={item.annual_amount} data-monthly={item.monthly_amount}>{item.annual_amount}</span>
                                <span className="font-price-small">€/{translate.month}</span>
                                <div align="center" className="font-sm-price" style={{minHeight: '30px'}}>
                            <span className="font-sm-price font-weight-black">
                                <span className="hideme">{item.annual_amount * 12} €/{translate.year}</span>
                            </span>
                                </div>
                                <div align="center">
                                    <OutboundLink href="https://www.tecalis.com/es/signature/go" className="btn btn-primary btn-sm spacing mt-4 mb-5">{translate.button}</OutboundLink>
                                </div>
                            </td>
                        } else {
                            return <td align="center" key={i}>
                                <span className="font-price1">{translate.enough}</span>
                                <div align="center" className="font-sm-price" style={{minHeight: '30px'}}>
                            <span className="font-weight-black">
                                <span style={{fontWeight: '500'}}>{translate.consult}</span>
                            </span>
                                </div>
                                <div align="center">
                                    <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="btn btn-primary btn-sm spacing mt-4 mb-5">
                                        {translate.contact_text} <span className="mobile1">{translate.sales}</span>
                                    </a>
                                </div>
                            </td>
                        }
                    })}
                </tr>
                </thead>
                <tbody>
                <tr className="spacing font-weight-bold">
                    <td>{translate.simple_signature}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            <div align="center">{item.simple_signature}</div>
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.advanced_biometric_signature}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            <div align="center">
                                {item.advanced_biometric_signature}<br/>
                                {item.advanced_biometric_signature_text && <span style={{fontSize: '12px', fontWeight: '400'}}>{item.advanced_biometric_signature_text}</span>}
                            </div>
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.certified_mail}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.certified_mail && <div align="center">{item.certified_mail}</div>}
                            {!item.certified_mail && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.additional_user}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.additional_user && <div align="center">{item.additional_user}</div>}
                            {!item.additional_user && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.alerts_and_reminders}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.alerts_and_reminders && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.alerts_and_reminders && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.custom_templates}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.custom_templates && <div align="center">{item.custom_templates}</div>}
                            {!item.custom_templates && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.shipment_scheduling}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.shipment_scheduling && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.shipment_scheduling && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.verification_dni}</td>

                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.verification_dni && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.verification_dni && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.mass_shipments}</td>
                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.mass_shipments && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.mass_shipments && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.organizations_teams}</td>
                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.organizations_teams && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.organizations_teams && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.branding}</td>
                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.branding && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.branding && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                <tr className="spacing font-weight-bold">
                    <td>{translate.api_integration}</td>
                    {items.map((item, i) => {
                        return <td key={i}>
                            {item.api_integration && <div align="center"><i className="fe fe-check icon-fe mr-2 text-green"/></div>}
                            {!item.api_integration && <div align="center"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/></div>}
                        </td>
                    })}
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PreciosAnuales
