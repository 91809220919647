import React from "react";
import {OutboundLink} from "gatsby-plugin-google-gtag";

const PreciosAnualesMobile = () => {
    return (
        <ul className="pricing-table">
            <li className="pricing-table__item">
                <h3 className="pricing-table__title spacing">Esencial</h3>
                <p align="center" className="pricing-table__description">
                    <span className="pricing-table__price spacing"><span className="price" data-annual="10" data-monthly="12">10</span><span>€</span></span>
                    <div align="center" className="font-sm-price">por usuario/mes<br/> <span className="price font-weight-black" data-annual="120"
                                                                                             data-monthly="144">120</span><span
                        className="font-sm-price font-weight-black">€/año</span></div>
                    <ul className="pricing-table__products">
                        <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>50 envíos al año de firma simple</li>
                        <li className="pricing-table__product spacing">
                            <i className="fe fe-check icon-fe mr-2 text-green"/>20 envíos al año sin límite de firmantes por envío (Firma avanzada biométrica)
                        </li>
                        <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Alertas y recordatorios</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Plantillas personalizadas</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Programación de envíos</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Verificación mediante DNI</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Envíos masivos</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Estructuras organizativas y equipos</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Personalización de marca</li>
                        <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Integración API</li>
                    </ul>
                    <OutboundLink href="https://www.tecalis.com/es/signature/go" className="btn btn-primary btn-sm spacing img-fluid">Comenzar prueba</OutboundLink>
                </p>
            </li>
            <li className="pricing-table__item">
                <h3 className="pricing-table__title spacing">Estándar</h3>
                <p align="center" className="pricing-table__description">
                    <span className="pricing-table__price spacing"><span className="price" data-annual="20" data-monthly="25">20</span><span>€</span></span>
                    <div align="center" className="font-sm-price">por usuario/mes<br/> <span className="price font-weight-black" data-annual="240"
                                                                                             data-monthly="300">240</span><span
                        className="font-sm-price font-weight-black">€/año</span></div>

                </p>
                <ul className="pricing-table__products">
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Firma simple ilimitada</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>75 envíos al año sin límite de firmantes por envío (Firma
                        avanzada biométrica)
                    </li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Alertas y recordatorios</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Hasta 3 plantillas personalizadas</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Programación de envíos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Verificación mediante DNI</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Envíos masivos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Estructuras organizativas y equipos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Personalización de marca</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Integración API</li>
                </ul>
                <OutboundLink href="https://www.tecalis.com/es/signature/go" className="btn btn-primary btn-sm spacing img-fluid">Comenzar prueba</OutboundLink>
            </li>
            <li className="pricing-table__item pricing-table__item--popular" data-popular="">
                <h3 className="pricing-table__title">Premium</h3>
                <p align="center" className="pricing-table__description">
                    <span className="pricing-table__price spacing"><span className="price" data-annual="40" data-monthly="45">40</span><span>€</span></span>
                    <div align="center" className="font-sm-price">por usuario/mes<br/> <span className="price font-weight-black" data-annual="480" data-monthly="540">480</span>
                        <span
                            className="font-sm-price font-weight-black">€/año</span></div>
                </p>
                <ul className="pricing-table__products">
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Firma simple ilimitada</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>300 envíos al año sin límite de firmantes por envío (Firma
                        avanzada
                        biométrica)
                    </li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Alertas y recordatorios</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Hasta 5 plantillas personalizadas</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Programación de envíos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Verificación mediante DNI</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Envíos masivos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Estructuras organizativas y equipos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Personalización de marca</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-x-circle icon-fe mr-2 text-tecalis"/>Integración API</li>
                </ul>
                <OutboundLink href="https://www.tecalis.com/es/signature/go" className="btn btn-primary btn-sm  spacing img-fluid">Comenzar prueba</OutboundLink>
            </li>
            <li className="pricing-table__item">
                <h3 className="pricing-table__title">Enterprise</h3>
                <p className="pricing-table__description">
                    <span className="pricing-table__price2"><b>¿No es suficiente?</b></span>
                    <span className="pricing-table__label spacing"><b>Consultanos sin compromiso</b></span>
                </p>
                <ul className="pricing-table__products">
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Firma simple ilimitada</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Volumen personalizado</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Notificaciones y recordatorios</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Plantillas personalizadas ilimitadas</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Programación de envíos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Verificación mediante DNI</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Envíos masivos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Estructuras organizativas y equipos</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Personalización de marca</li>
                    <li className="pricing-table__product spacing"><i className="fe fe-check icon-fe mr-2 text-green"/>Integración API</li>
                </ul>
                <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="btn btn-primary btn-sm  spacing img-fluid">
                    Contactar con ventas
                </a>
            </li>
        </ul>
    )
}

export default PreciosAnualesMobile
