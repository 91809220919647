import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import SignatureNav from "../../components/common/signature-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/signature/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import PreciosAnuales from "../../components/signature/prices-annual";
import PreciosAnualesMobile from "../../components/signature/prices-annual-mobile";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const PreciosPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiSignaturePrecios.edges[0].node;

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-sig.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <SignatureNav pageContext={pageContext}/>

            <section id="product-top" className="content pricing mb-0 bg-grey" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="mt-7 alert alert-info-tecalis alert-dismissible spacing fade show" role="alert" data-aos="fade-down">
                                {page.price_alert}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="" id="table--desktop" style={{marginTop: '-20px'}}>
                                <div className="card card--price">
                                    <div className="card-body-pricing card--price--body bg-white">
                                        <div className="row">
                                            <div className="col-xl-10">
                                                <h4 className="font-weight-bold spacing pt-2 pl-2"> {page.price_title}</h4>
                                            </div>
                                            <div className="col-xl-2">
                                                <form className="d-flex text-black planes-sw">
                                                    <span className="text-black font-weight-bold spacing mr-2">Anual</span>

                                                    <div className="custom-control custom-switch custom-switch-light mx-3">
                                                        <input type="checkbox" className="custom-control-input" id="billingSwitch" data-toggle="price" data-target=".price"/>
                                                        <label className="custom-control-label" htmlFor="billingSwitch"/>
                                                    </div>

                                                    <span className="text-black font-weight-bold spacing">Mensual</span>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <PreciosAnuales items={page.price} lang={lang}/>

                                <br/><br/>
                            </div>
                        </div>
                    </div>

                    <div className="desktop mt-5 mb-5" id="table--mobile">
                        <div className="pricing-table-wrapper">
                            <br/>

                            <PreciosAnualesMobile/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 pt-10 pb-8 links bg-grey-1">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={require("../../img/products/ic_decoration_white.svg").default} alt=""/>
                </div>

                <div className="container">
                    <div className="links-wrapper">
                        <div className="row links-card">
                            {page.packs.map((pack, i) => {
                                return <div className="col-xl-6 mb-5" key={i}>
                                    <div className="card hvr-float price--card">
                                        <div className="card-body">
                                            <div align="center">
                                                <h5><span className="badge rounded-pill bg-tecalis text-white mt-3 py-2 pr-3 pl-3">{pack.tag}</span></h5>
                                                <h3>{pack.name}</h3>
                                            </div>

                                            <ReactMarkdown children={pack.description} rehypePlugins={[rehypeRaw]} components={customElements('text-black-80 spacing mt-4 mb-4 pl-5 pr-5 text-left')}/>

                                            <br/>

                                            <p align="center">
                                                <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="link-tecalis pl-5 pr-5 spacing font-weight-bold">
                                                    {pack.button} 👉
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Signature"/>

            <ModalContactSales pageContext={pageContext} product="Signature"/>

            <ModalWhitePaper pageContext={pageContext}/>

            {/*<ModalSignatureDemo/>*/}
        </LayoutThird>
    )
}

export default PreciosPage

export const preciosPageQuery = graphql
    `query($lang: String) {
        allStrapiSignaturePrecios ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    price_alert
                    price_title
                    price {
                        order
                        name
                        monthly_amount
                        annual_amount
                        contact_sales
                        simple_signature
                        advanced_biometric_signature
                        advanced_biometric_signature_text
                        certified_mail
                        additional_user
                        alerts_and_reminders
                        custom_templates
                        shipment_scheduling
                        verification_dni
                        mass_shipments
                        organizations_teams
                        branding
                        api_integration
                    }
                    packs {
                        order
                        tag
                        name
                        description
                        button
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
